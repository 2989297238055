import React from 'react';
import {Header} from "./Header";

export const Information = () => {
    window.scrollTo(0, 0);
    return (
        <div>
        <Header></Header>
        <div>
        <section className="info-section">
            <div className="head-info">
                <h2>Правила відвідування спортивного клубу, техніка безпеки</h2>
                <p>Правила розроблені з метою створення безпечних та комфортних умов для тренувань.
                    Правила є обов'язковими для всіх відвідувачів спортивного клубу. <br/> У разі порушення правил адміністрація клубу
                    залишає за собою право на дострокове припинення абонементу.
                   У разі потреби правила можуть бути переглянуті чи доповнені.
                    Адміністрація та персонал спортивного клубу не несуть відповідальності за речі залишені у шафах та за речі
                    залишені без нагляду у тренажерному залі.</p>
            </div>
            <div className="section-content">
                <h3>
                    ОФОРМЛЕННЯ АБОНЕМЕНТА
                </h3>
                <p>1. ПРОПУСКОМ У ТРЕНАЖЕРНИЙ ЗАЛ Є АБОНЕМЕНТ І ПРЕД'ЯВЛЯЄТЬСЯ ПРИ КОЖНОМУ ВІДВІДАННІ ТРЕНАЖЕРНОЇ ЗАЛИ.
                    Адміністратор має право не пропустити клієнта без картки у спортивний клуб.</p>
                <p>2. Клієнт зобов'язаний сплатити абонемент або разове відвідування занять відповідно до чинного
                    прейскурант.</p>
                <p>3. Абонемент є персональним (іменним).</p>
                <p>4. Передача абонементу третій особі або переведення днів відвідин на інший абонемент – заборонено.</p>
                <p>5. При оформленні абонементу зазначення особистих даних:<br/><br/>
                    - П.І.Б.(повністю);<br/>
                    - дата народження (число, місяць, рік);<br/>
                    - номер мобільного телефону;<br/>
                    - Фотографія (особи клієнта).<br/>
                </p>
                <div className="section-services">
                    <div>
                        <h5>РОЗОВЕ ТРЕНУВАННЯ</h5>
                        <p>Для покупки разового тренування Вам необхідно вказати адміністратору Ваші особисті дані - П.І.Б. (прізвище та ініціали).<br/>
                            Надати документ, що засвідчує Вашу особу.</p>
                    </div>
                    <div>
                        <h5>ЗАМОРОЗКА АБОНЕМЕНТА</h5>
                        <p>1. Послуга надається один раз за продовження абонементу.</p>
                        <p>2. Річні, абонементи на 3 та 6 місяців, акційні абонементи не заморожуються.</p>
                        <p>3. Мінімальне заморожування абонементу – 5 календарних днів.</p>
                        <p>4. Заморозка здійснюється в день, коли клієнт повідомив адміністратора або відправив смс про заморожування.</p>
                    </div>
                    <div>
                        <h5>ЯК ЗАМОРОЗИТИ АБОНЕМЕНТ</h5>
                        <p>- Поінформуйте адміністратора особисто під час останнього відвідування спортивного клубу.</p>
                        <p>- Надішліть та збережіть повідомлення із зазначенням П.І.Б., адресу клубу, число з “якого” та по “яке” Ви бажаєте заморозити абонемент (на номер телефону +380678426488!!!).</p>
                    </div>
                    <div>
                        <h5>ТРЕНУВАННЯ З ТРЕНЕРОМ</h5>
                        <p>Ви маєте право самостійно вибрати тренера, з яким бажаєте займатися. Оплата індивідуального тренування здійснюється адміністратору тренажерного залу.</p>
                    </div>
                </div>
            </div>
        </section>

    <section className="info-safety-section">
        <div className="head-info">
            <h2>Правила техніки безпеки під час занять у тренажерному залі</h2>
            <p>1. До самостійних занять у тренажерному залі допускаються особи, які не мають медичних протипоказань.</p>
            <p>2. Клієнти шкільного віку оформляють абонемент, відповідно до чинного прейскуранта.</p>
            <p>3. Діти шкільного віку можуть відвідувати тренажерний зал тільки у супроводі батьків або займаючись з інструктором, до самостійних тренувань допускаються тільки за умови письмової згоди одного з батьків. Некоректна поведінка дітей, що залишається поза увагою дорослого, що супроводжує, може бути причиною зупинки клубної карти останнього.</p>
            <p>4. Заняття в тренажерному залі дозволені тільки в чистому змінному спортивному взутті, рекомендується використовувати зручне закрите взуття з гумовою та нековзною підошвою. Забороняється використовувати взуття на високих підборах, зі шкіряною підошвою, з шипами, тренуватися босоніж або у відкритому взутті.</p>
            <p>5. Заняття у тренажерному залі дозволені у зручному одязі, що не сковує руху. Забороняється тренуватися з оголеним торсом. Під час тренувань бажано використовувати рушники.</p>
            <p>6. Рекомендується зняти з себе предмети, що становлять небезпеку при заняттях у тренажерному залі (годинник, висячі сережки тощо), прибрати з кишень колючі та інші сторонні предмети.</p>
            <p>7. Дотримуйтесь правил особистої гігієни, під час тренування використовуйте особистий рушник, не застосовуйте різких парфумерних запахів.</p>
            <p>8. Виконання базових вправ (жим лежачи, присідання зі штангою) потрібно проводити при страховці з боку партнера чи інструктора тренажерного залу.</p>
            <p>9. Не допускається перевантаження тренажерних пристроїв понад норму додатковим навішуванням вантажів.</p>
            <p>10. При виконанні базових вправ (станова тяга, присідання тощо) бажано користуватися атлетичним поясом.</p>
            <p>11. Після виконання вправ слід прибрати спортивне обладнання, що використовується (млинці, гантелі, штанги, інвентар і т.д.) на спеціально відведені місця.</p>
            <p>12. Забороняється торкатися рухомих частин блокових пристроїв.</p>
            <p>13. Забороняється ставити пляшки з водою на тренажери, підключені до електромережі (не підстаканники).</p>
            <p>14. На початку тренування на біговій доріжці необхідно ставати на підставки по краях рушійної стрічки, встановити мінімальну швидкість і розпочинати тренування після того, як стрічка почала рухатися. Не можна сходити з тренажера до повної зупинки стрічки.</p>
            <p>15. Забороняється користуватися аварійною зупинкою бігової доріжки (червона кнопка STOP на консолі бігової доріжки) без потреби. Для штатної зупинки полотна на консолі передбачено кнопку зупинки.</p>
            <p>16. Прийом та зберігання їжі у зонах, призначених для тренувань, заборонено.</p>
            <p>17. Забороняється самостійно регулювати систему кондиціювання та вентиляції залу, гучність трансляції та підбір музичного супроводу.</p>
            <p>18. При поломці або псуванні спортивного інвентарю необхідно припинити заняття та повідомити про це інструктора тренажерного залу. Заняття продовжуватимуть лише після усунення несправностей або заміни спортивного обладнання (інвентарю).</p>
            <p>19. У разі отримання травми, внаслідок недотримання техніки безпеки спортивний клуб відповідальності не несе.</p>
            <p>20. Адміністрація спортивного клубу не несе відповідальності за матеріальні збитки, а також збитки, завдані здоров'ю клієнта, отримані внаслідок некоректного використання ним (клієнтом) обладнання, предметів інтер'єру, частин приміщення, інженерних комунікацій, систем життєзабезпечення, а також всіх інших предметів, що перебувають у спортивному клубі.</p>
            <p>21. Клуб не несе відповідальності за травми, пошкодження, отримані клієнтами під час тренування через хронічні захворювання, захворювання опорно-рухового апарату, серцево-судинну систему тощо. Про всі протипоказання клієнт зобов'язаний проінформувати адміністратора і тренера спортивного клубу.</p>
            <p>22. Паркування особистого транспорту (велосипеди, самокати, мопеди та інше) суворо у зонах паркування. Занесення велосипедів, самокатів та ін. в зал суворо заборонено.</p>
            <p>23. Категорично заборонено вживати в клубі спиртні напої, а також психотропні та наркотичні препарати.</p>
            <p>24. При псуванні майна спортклубу, випадкової чи навмисної адміністрація клубу залишає у себе визначення суми матеріально шкоди завданої клієнтом, і навіть шляхи його відшкодування.</p>
            <div className="bottom-text">
                <i>Дирекція клубу має право у разі порушення клієнтом чинних правил попередити клієнта та заборонити йому відвідувати тренажерний зал без фінансової компенсації за грубого порушення цих правил!</i>
            </div>
        </div>
    </section>
        </div>
        </div>
    );
};
