import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../store/user';
import modalReducer from '../store/modal';

export default configureStore({
  'reducer': {
    'user': userReducer,
    'modal': modalReducer
  }
});
