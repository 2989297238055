import React from 'react';
import { NavLink } from 'react-router-dom';
import './Footer.scss';
import alphaFit from "../../../../resources/img/alpah-fit-logo.svg";

export const Footer = () => {
  return (
    <footer className={'app-footer'}>
      <NavLink to="/" className="logo" >
        <img src={alphaFit} alt="" />
      </NavLink>
      <div className="app-footer-context"> @ 2020 ALPHAFIT, All rights reserved</div>
      <div className="social-links">
        <a href="https://www.instagram.com/alphafit.pro/" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-instagram fa-lg"/>
        </a>
        <a href="https://t.me/joinchat/UerDGb0ctxQ1jKuq" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-telegram fa-lg"/>
        </a>
        <a href="mailto:alphafit.pro2020@gmail.com" target="_blank" rel="noopener noreferrer">
          <i className="far fa-envelope fa-lg"/>
        </a>
      </div>
    </footer>
  );
};
