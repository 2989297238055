import React from 'react';
import { closeModal } from "../../store/actions";
import { useDispatch } from 'react-redux';
import './Modal.scss';
import alphaFitLogo from "../../resources/img/alpah-fit-logo.svg";
import close from "../../resources/img/close.svg";

export const Modal = () => {
  const dispatch = useDispatch();
  window.scrollTo(0, 0);
  return (
    <div className="modal-main-section">
        <div className="modal-content">
            <div className="modal-close">
                <button
                    type="button"
                    className="close-btn"
                    onClick={() => dispatch(closeModal())}
                >
                    <img src={close} alt="" />
                </button>
            </div>
            <img src={alphaFitLogo} alt=">" />
            <h3>Використовуй соцмережі та отримай абонемент безкоштовно</h3>
            <div className="modal-section-container">
                <div className="section-left" >
                    <h5>500+ передплатників у Instagram</h5>
                    <p>- Роби історію з нашою залою<br/>
                        - Відзначай геолокацію<br/>
                        - Отримай перше відвідування безкоштовно
                    </p>
                </div>
                <div className="section-right">
                    <h5>30k+ передплатників у Instagram</h5>
                    <p>
                       - Кожне тренування роби історію з нашою залою<br/>
                       - Відзначай геолокацію<br/>
                       - Отримуй абонемент безкоштовно
                    </p>
                </div>
            </div>
            <div>
                <div className="social-links">
                    <a href="https://www.instagram.com/alphafit.pro/" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-instagram fa-lg"/>
                    </a>
                    <a href="https://t.me/joinchat/UerDGb0ctxQ1jKuq" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-telegram fa-lg"/>
                    </a>
                    <a href="mailto:alphafit.pro2020@gmail.com" target="_blank" rel="noopener noreferrer">
                        <i className="far fa-envelope fa-lg"/>
                    </a>
                    <a href="https://www.youtube.com/channel/UCH4e6Gz4Qi3HxmDZYwaZ9yQ" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-youtube fa-lg"/>
                    </a>
                </div>
            </div>
        </div>
    </div>
  );
};
