import React from 'react';
import './App.scss';
import { Route, Switch } from 'react-router-dom';
import {Footer} from './pages/Home/MainSection/Footer';
import { Modal } from './pages/Modal';
import { useSelector } from 'react-redux';
import {selectModal} from './store/selectors';
import {MainSection} from "./pages/Home/MainSection";
import {Information} from "./pages/Information";

function App() {
    const {isOpen} = useSelector(selectModal);

    return (
        <div className="App">
            {isOpen && <Modal />}
            <Switch>
              <Route exact path="/" component={MainSection}/>
              <Route exact path="/information" component={Information}/>
            </Switch>
            <Footer/>
        </div>
    );
}

export default App;
