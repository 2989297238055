import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  'name': 'user',
  'initialState': {
    firstName: '',
    isAuthenticated: false,
  },
  'reducers': {
    login: (state, action) => {
      state.firstName = action.payload.firstName;
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.firstName = '';
      state.isAuthenticated = false;
    },
  }
});

export const { login, logout } = userSlice.actions;

export default userSlice.reducer;
