import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import location from '../../../resources/img/maps-and-flags.svg';
import menuIcon from '../../../resources/img/menu_icon.svg';
import alphaFit from '../../../resources/img/alpah-fit-logo.svg';
import './Header.scss';

export const Header = () => {
  const [isMobileNavigation, showMobileNavigation] = useState(false);

  const switchMobileMenu = () => {
    showMobileNavigation(!isMobileNavigation);
  };

  return (
    <header className={`App-header${isMobileNavigation ? ' fixed': ''}`}>
      <div className="mobile-location">
        <div className="mobile-location_address">
          <img src={location} alt="" />
          <span>ул. Победы 74</span>
        </div>
        <a className="mobile-location_phone" href="tel:+38(099)107-12-12">+38(099)107-12-12</a>
      </div>
      <NavLink to="/" className="logo" >
        <img src={alphaFit} alt="" />
      </NavLink>
      <div className={`navigation${isMobileNavigation ? ' mobile-version': ''}`}>
          <div className="working-hours">
              ГРАФIК РОБОТИ <br/>
              Пн. - Пт. 8.00 - 21.00 <br/>
              Сб. 9:00 - 20:00 <br/>
              Нд. вихідний  <br/>
          </div>
        <NavLink to="/" >Про Клуб</NavLink>
        <NavLink to="/" >Фото</NavLink>
        <NavLink to="/" >Контакти</NavLink>
        <NavLink to="/" >Тренерам</NavLink>
        <NavLink to="/information" >Інформація</NavLink>
        <div className="social-links-mobile">
              <a href="https://www.instagram.com/alphafit.pro/" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-instagram fa-lg"/>
              </a>
              <a href="https://t.me/joinchat/UerDGb0ctxQ1jKuq" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-telegram fa-lg"/>
              </a>
              <a href="https://www.youtube.com/channel/UCH4e6Gz4Qi3HxmDZYwaZ9yQ" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-youtube fa-lg"/>
              </a>
        </div>
      </div>
      <div className="social-links">
        <a href="https://www.instagram.com/alphafit.pro/" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-instagram fa-lg"/>
        </a>
        <a href="https://t.me/joinchat/UerDGb0ctxQ1jKuq" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-telegram fa-lg"/>
        </a>
        <a href="https://www.youtube.com/channel/UCH4e6Gz4Qi3HxmDZYwaZ9yQ" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-youtube fa-lg"/>
        </a>
      </div>
      <div
        className="mobile-navigation"
        onClick={switchMobileMenu}
      >
        <img src={menuIcon} alt="menu" />
      </div>
    </header>
  );
};
