import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import './map.scss';


const containerStyle = {
  width: '100%',
  height: '60rem'
};

const center = {
  lat: 50.060067,
  lng: 36.203240
};

function GMap() {
  return (
    // <LoadScript
    //   googleMapsApiKey="AIzaSyDEOOr0NKIUrd5egzjXgiKZ3aR-Ww9g0kA"
    // >
    //   <GoogleMap
    //     mapContainerStyle={containerStyle}
    //     center={center}
    //     zoom={10}
    //   >
    //     <Marker position={center}/>
    //   </GoogleMap>
    // </LoadScript>
      <div className={'mapImage'}/>
  );
}

export default React.memo(GMap);