import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../store/actions';
import '../../Information/Header/Header.scss';
import './MainSection.scss';
import chevronRight from '../../../resources/img/right-chevron.svg';
import hand from '../../../resources/img/hand.svg';
import crsImgFirst from '../../../resources/img/photo1.jpg';
import crsImgSecond from '../../../resources/img/photo2.jpg';
import crsImgThird from '../../../resources/img/photo3.jpg';
import clubAtm from '../../../resources/img/club-atm.svg';
import clubProgram from '../../../resources/img/club-program.svg';
import clubEg from '../../../resources/img/club-eq.svg';
import alphaLogWhite from '../../../resources/img/alpah-fit-card-white.svg';
import alphaLogBlack from '../../../resources/img/alpah-fit-card-black.svg';
import alphaFitBig from '../../../resources/img/alpah-fit-big.svg';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import GMap from "./GMap";
import location from "../../../resources/img/maps-and-flags.svg";
import {NavLink} from "react-router-dom";
import alphaFit from "../../../resources/img/alpah-fit-logo.svg";
import menuIcon from "../../../resources/img/menu_icon.svg";

export const MainSection = () => {
  const dispatch = useDispatch();
  const secondSectionRef = useRef();
  const gallerySectionRef = useRef();
  const contactSectionRef = useRef();
  const trainerSectionRef = useRef();
  const [isMobileNavigation, showMobileNavigation] = useState(false);

  const switchMobileMenu = () => {
    showMobileNavigation(!isMobileNavigation);
  };

  const scrollToRef = (resultsRef = 0) => {
    window.scrollTo({
      behavior: "smooth",
      top: resultsRef === 0 ? 0: resultsRef.current.offsetTop - 80
    });

    showMobileNavigation(false);
  }

  return (
    <div className="home-page">
      <header className={`App-header${isMobileNavigation ? ' fixed': ''}`}>
        <div className="mobile-location">
          <div className="mobile-location_address">
            <img src={location} alt="" />
            <span>ул. Победы 74</span>
          </div>
          <a className="mobile-location_phone" href="tel:+38(099)107-12-12">+38(099)107-12-12</a>
        </div>
        <NavLink to="/" className="logo" >
          <img src={alphaFit} alt="" />
        </NavLink>
        <div className={`navigation${isMobileNavigation ? ' mobile-version': ''}`}>
          <div className="working-hours">
            ГРАФIК РОБОТИ <br/>
            Пн. - Пт. 8.00 - 21.00 <br/>
            Сб. 9.00 - 17.00 <br/>
            Нд. вихідний <br/>
          </div>
          <a href="javascript:;" activeClassName="active-link" onClick={() => scrollToRef(secondSectionRef)}>Про Клуб</a>
          <a href="javascript:;" activeClassName="active-link" onClick={() => scrollToRef(gallerySectionRef)}>Фото</a>
          <a href="javascript:;" activeClassName="active-link" onClick={() => scrollToRef(contactSectionRef)}>Контакти</a>
          <a href="javascript:;" activeClassName="active-link" onClick={() => scrollToRef(trainerSectionRef)}>Тренерам</a>
          <NavLink to="/information" activeClassName="active-link">Інформація</NavLink>
          <div className="social-links-mobile">
            <a href="https://www.instagram.com/alphafit.pro/" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram fa-lg"/>
            </a>
            <a href="https://t.me/joinchat/UerDGb0ctxQ1jKuq" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-telegram fa-lg"/>
            </a>
            <a href="https://www.youtube.com/channel/UCH4e6Gz4Qi3HxmDZYwaZ9yQ" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-youtube fa-lg"/>
            </a>
          </div>
        </div>
        <div className="social-links">
          <a href="https://www.instagram.com/alphafit.pro/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram fa-lg"/>
          </a>
          <a href="https://t.me/joinchat/UerDGb0ctxQ1jKuq" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-telegram fa-lg"/>
          </a>
          <a href="https://www.youtube.com/channel/UCH4e6Gz4Qi3HxmDZYwaZ9yQ" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-youtube fa-lg"/>
          </a>
        </div>
        <div
            className="mobile-navigation"
            onClick={switchMobileMenu}
        >
          <img src={menuIcon} alt="menu" />
        </div>
      </header>
      <main className="main-section">
        <div className="mobile-workable-hours">
          Пн. - Пт. 8.00 - 21.00 <br/>
          Сб. 9.00 - 17.00 <br/>
          Нд. вихідний <br/>
        </div>
        <section className="first-section">
          <p className="first-section-content">Світ ділиться на два класи<br/> <span>одні вірять у неймовірне,</span><br/>
           інші роблять неможливе.
          </p>
          <button
              type="button"
              className="main-action"
              onClick={() => dispatch(openModal())}
          >
            ЗРОБИ ВИБІР
            <img src={chevronRight} alt=">" />
          </button>
        </section>

        <section className="second-section" ref={secondSectionRef}>
          <div className="second-section-alpha ui-block">
            <h2>Про клуб</h2>
            <p>Перший клуб, який провокує на роботу своєю атмосферою та найдоступнішими цінами!</p>
          </div>
          <div className="ui-block">
            <div className="ui-ways brd-bottom">
              <div className="brd-right">
                <img src={clubAtm} alt='АТМОСФЕРА'/>
                АТМОСФЕРА
              </div>
              <div>
                <img src={clubProgram} alt='УДОБСТВО ПАРТНЁРАМ'/>
                ЗРУЧНІСТЬ
              </div>
            </div>
            <div className="ui-ways">
              <div className="brd-right">
                <img src={clubEg} alt='ОБОРУДОВАНИЕ'/>
                УСТАТКУВАННЯ
              </div>
              <div>
                <img src={hand} alt='ЦЕНА'/>
                ЦІНА
              </div>
            </div>
          </div>
        </section>

        <section className="forms-section">
          <div className="cards-section">
            <div className="forms-card-first">
              <img src={alphaLogWhite} alt='Разовая тренировка'/>
              <h5>Разове Відвідування</h5>
              <p>150 <span>грн.</span></p>
            </div>
            <div className="forms-card-second">
              <img src={alphaLogWhite} alt='Абонемент на 1 месяц'/>
              <h5>Абонементи від</h5>
              <p>500 <span>грн.</span></p>
            </div>
            <div className="forms-card-third">
              <img src={alphaLogBlack} alt='Абонемент на 3 месяца'/>
              <h5>Безліміт на 3 місяці</h5>
              <p>1650 <span>грн.</span></p>
            </div>
          </div>
          <div className="forms-card-text">
            <p>Будь з нами та отримай абонемент безкоштовно, за допомогою своїх соцмереж ( дізнавайся подробиці на ресепшені)</p>
          </div>
          <div className="desc-section">
            <h1>Ми пропонуємо вам отримувати всю необхідну інформацію про тренування та вправи онлайн.</h1>

            <button
                type="button"
                className="grey-btn"
                onClick={() => window.open("https://alphafit.pro/")}
            >
              ОНЛАЙН-ТРЕНІНГ
            </button>
          </div>
        </section>

        <section className="gallery-section" ref={gallerySectionRef}>
          <div className="ui-block photo-alpha-fit">
            <div className="alpha-fit-content">
              <h2>ПРИДУМАЙ МЕТУ, А МИ ЗРОБИМО ЇЇ РЕАЛЬНОЮ!</h2>
            </div>
          </div>
          <div className="ui-block carousel">
            <Carousel autoPlay={true} showStatus={false} showIndicators={false} infiniteLoop={true} showThumbs={false}>
              <div>
                <img src={crsImgFirst} alt='carousel 1'/>
              </div>
              <div>
                <img src={crsImgSecond} alt='carousel 2'/>
              </div>
              <div>
                <img src={crsImgThird} alt='carousel 3'/>
              </div>
            </Carousel>
          </div>
        </section>

        <section className="contact-section" ref={contactSectionRef}>
          <div className="contact-info">
            <h3>Контакти</h3>
            <h5>Адреса: <br/>
             м. Харків, проспект Перемоги 62</h5>
            <h5>Телефон: <br/>
              +38 (067) 842-64-88
            </h5>
            <h5>ГРАФIК РОБОТИ : <br/>
              Пн. - Пт. 8.00 - 21.00 <br/>
              Сб. 9.00 - 17.00 <br/>
              Нд. вихідний <br/>
              </h5>

            <div className="social-links">
              <a href="https://www.instagram.com/alphafit.pro/" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram fa-lg"/>
              </a>
              <a href="https://t.me/joinchat/UerDGb0ctxQ1jKuq" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-telegram fa-lg"/>
              </a>
              <a href="mailto:alphafit.pro2020@gmail.com" target="_blank" rel="noopener noreferrer">
                <i className="far fa-envelope fa-lg"/>
              </a>
              <a href="https://www.youtube.com/channel/UCH4e6Gz4Qi3HxmDZYwaZ9yQ" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-youtube fa-lg"/>
              </a>
            </div>
          </div>
          <GMap
              isMarkerShown
              googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
              loadingElement={<div style={{ height: '100%' }} />}
              containerElement={<div style={{ height: '600px' }} />}
              mapElement={<div style={{ height: '100%' }} />}
          />
        </section>

        <section className="program-section" ref={trainerSectionRef}>
          <div className="alpha-fit-log">
            <img src={alphaFitBig} alt=">"/>
            <h3>Тренерська програма</h3>
          </div>
          <div className="program-section-content">
            <div className="ui-block">
              <h5>ПОЗАШТАТНИЙ ТРЕНЕР</h5>
              <p>100 грн із клієнта.</p>
            </div>
            <div className="ui-block">
              <h5>ТРЕНЕР У ШТАТ</h5>
              <p>Приєднуючись до команди ALPHAFIT як штатний тренер, ти стабільно отримуєш <b>50%</b> від клубу з кожного клієнта. А також просунутий процес розподілу тренувань і, зрештою, зручний та гнучкий формат роботи.</p>

            </div>
          </div>
        </section>

      </main>
    </div>
  );
};
